import '../styles/bubbleprotects.scss';
import '../styles/testinomial.scss';

import React, { useEffect, useState } from 'react';

import Layout from '../components/layout';
import fullstarone from '../images/strfull-1.svg';
import fullstar from '../images/strfull.svg';


function testimonial() {

  const [testimonials, addTestimonials] = useState( [] );
  //|| process.env.GATSBY_NEST_URL
  useEffect( async() => {
    const resp = await fetch( 'https://app.getmybubble.co/api/'  + 'states/testimonial' );
    const data = await resp.json();
    addTestimonials( data || [] );
  } , [] );

  return(
    <Layout header={'menu'}>
      <div style={{display: "none"}} className="wrapper">
        <div className="blog-box">
          <div className="container common-container-space">
            <div className="row">
              <div className="col-12 text-center">
                <h2>Customer testimonials</h2>
              </div>

              <div className="col-12 testinomialbox testimonialouterbox">
                <div className="row">
                  {
                    testimonials.map( ( post, i ) => {
                      return ( <div className="col-12 mb-3" key={i}>
                        <div className="card card-block profile d-flex flex-auto">
                          <div className="card-body">
                            <div className="testiinr">
                              <div className="minht">
                                <div className="str">
                                  <img src={post.rating == 5 ? fullstar : fullstarone} loading="lazy" alt="" />
                                </div>

                                <h3>{post.name}</h3>

                                <p className="subtxt">{post.subtext}</p>
                              </div>

                              <p>
                                {post.body}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div> );
                    } )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default testimonial;
